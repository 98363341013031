import React, { useEffect, useState, useRef } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/all";
import sanDiegoImage from "../src/images/san-diego-2.5.jpg";
import { TextPlugin } from "gsap/TextPlugin";

function CorePrincipals() {
  const [isDarkSide, setIsDarkSide] = useState(false);
  const rowRefs = useRef([]);
  const textRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);

    const textElement = textRef.current;

    if (textElement) {
      const spans = textElement.querySelectorAll(".text-line");
      let animationTriggered = false;

      gsap.set(spans, { opacity: 0 });

      ScrollTrigger.create({
        trigger: spans,
        start: "top 90%", // Trigger animation when 300px from the bottom of the viewport
        onEnter: () => {
          if (!animationTriggered) {
            gsap.fromTo(
              spans,
              { rotation: 5, y: 100, opacity: 0 },
              {
                rotation: 0,
                y: 0,
                opacity: 1,
                stagger: 0.2,
                duration: 1,
                ease: Power3.easeOut,
                delay: 0.5, // Add a delay of 0.5s
              }
            );
            animationTriggered = true;
          }
        },
      });
    }
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage >= 30) {
        document.body.classList.add("dark-side");
      } else {
        document.body.classList.remove("dark-side");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };


  }, []);

  return (
    <section>
      <div className="grid">
        <div className="core-principals">
          <div className="section-title core-principals__title">
            <div className="text-mask">
              <div className="text-line">
                <h2>What I do</h2>
              </div>
            </div>
          </div>
          <div className="core-principals__content">
            <div className="grid row" ref={(el) => (rowRefs.current[0] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">01</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Product Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Designing intuitive, scalable products by blending creativity with data-driven decision-making. Aligning user needs with business objectives to deliver impactful, user-centered solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">02</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>UX/UI Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Designing user-centric interfaces through strategy, prototyping, and iteration. Balancing creativity with technical execution to ensure products align with goals and deliver exceptional user experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[1] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">03</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Mobile Experience Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Creating mobile-first, responsive designs that adapt fluidly across devices. Addressing navigation, performance, and usability challenges to deliver seamless and intuitive mobile experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">04</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>User-Centered Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Prioritizing user research to craft intuitive interfaces that resonate deeply. Creating seamless, engaging experiences that address user needs and drive satisfaction and usability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[2] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">05</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Creative Strategy</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Crafting digital products by blending creativity and technology. Transforming brand visions into captivating, impactful experiences that resonate with users from ideation through execution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column span-6">
                <div className="flat-card__number">06</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Universal User Experiences</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Adhering to Universal Design principles to ensure products are accessible and usable by the widest possible audience, eliminating barriers without the need for specialized design adaptations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[3] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">07</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Front End Development</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Converting design concepts into functional, reusable components using SASS and markup. Seamlessly integrating them into CMS to deliver responsive, dynamic, and cross-browser compatible experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">08</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Search Engine Optimization</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Optimizing digital content through targeted keyword research, on-page enhancements, and meta tags. Driving higher search rankings, increased visibility, and sustainable organic traffic growth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </section>
  );
}

export default CorePrincipals;
