import React, { useEffect, useState, useRef } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/all";
import sanDiegoImage from "../src/images/san-diego-2.5.jpg";
import { TextPlugin } from "gsap/TextPlugin";

function CorePrincipals() {
  const [isDarkSide, setIsDarkSide] = useState(false);
  const rowRefs = useRef([]);
  const textRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);

    const textElement = textRef.current;

    if (textElement) {
      const spans = textElement.querySelectorAll(".text-line");
      let animationTriggered = false;

      gsap.set(spans, { opacity: 0 });

      ScrollTrigger.create({
        trigger: spans,
        start: "top 90%", // Trigger animation when 300px from the bottom of the viewport
        onEnter: () => {
          if (!animationTriggered) {
            gsap.fromTo(
              spans,
              { rotation: 5, y: 100, opacity: 0 },
              {
                rotation: 0,
                y: 0,
                opacity: 1,
                stagger: 0.2,
                duration: 1,
                ease: Power3.easeOut,
                delay: 0.5, // Add a delay of 0.5s
              }
            );
            animationTriggered = true;
          }
        },
      });
    }
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage >= 30) {
        document.body.classList.add("dark-side");
      } else {
        document.body.classList.remove("dark-side");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };


  }, []);

  return (
    <section>
      <div className="grid">
        <div className="core-principals">
          <div className="section-title core-principals__title">
            <div className="text-mask">
              <div className="text-line">
                <h2>What I do</h2>
              </div>
            </div>
          </div>
          <div className="core-principals__content">
            <div className="grid row" ref={(el) => (rowRefs.current[0] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">01</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Front End Development</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        I convert intricate design concepts into functional and
                        reusable components using SASS and markup. Seamlessly
                        integrating these components into the CMS, I bring the
                        project to life, delivering responsive, dynamic, and
                        engaging user experiences. The fusion of design
                        aesthetics with technical implementation fuels my drive
                        to craft visually stunning and high-performing Front End
                        solutions that cater to a diverse audience while
                        ensuring cross-browser compatibility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">02</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>UX/UI Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        My design process encompasses strategy, prototyping,
                        refinement, and development, all with a focus on
                        creating exceptional user experiences that align with
                        the defined end goals. Through iterative fine-tuning of
                        designs, I ensure that the end product resonates well
                        with the target audience. Taking a hands-on approach, I
                        bring ideas to life with a seamless fusion of creativity
                        and technical expertise, handling both the design and
                        development aspects of projects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[1] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">03</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Creative Strategy</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Crafting powerful narratives that align with a brand
                        vision requires a meticulous approach to creative
                        strategy. I bring forth imaginative ideas and leverage
                        the latest technological advancements to craft
                        captivating experiences that resonate with users and
                        align with the established vision. Through a seamless
                        blend of creativity and technical expertise, I create
                        visually stunning digital products from ideation to
                        execution, leaving a lasting impression on users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">04</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Universal User Experiences</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        From the first wireframe to the final product, my goal
                        is to eliminate barriers and provide equal access and
                        participation for everyone, ensuring that no one is
                        excluded from interacting with digital interfaces. I use
                        the principals of Universal Design as my North star,
                        which advocates designing products that are usable by
                        the widest range of people possible, without the need
                        for adaptation or specialized design.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[2] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">05</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>User-Centered Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        User-centricity drives my design process, prioritizing
                        comprehensive research to understand user needs and
                        behaviors. The result is a collection of seamless and
                        engaging experiences that deeply resonate with the
                        target audience. Crafting intuitive user flows and
                        interactive interfaces, I aim to facilitate effortless
                        engagement with digital products.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column span-6">
                <div className="flat-card__number">06</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Search Engine Optimization</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Harnessing the power of SEO, I optimize digital content
                        to improve visibility and drive organic traffic. By
                        conducting thorough keyword research, implementing
                        on-page optimizations, and crafting compelling meta
                        tags, I ensure websites are primed for higher search
                        engine rankings and increased discoverability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CorePrincipals;
