import React, { useEffect, useRef } from "react";
import { gsap, Power3 } from "gsap";
import sanDiegoImage from "../src/images/san-diego-2.8.jpg";
import { TextPlugin } from "gsap/TextPlugin";

function HomeHero() {
  const textRef = useRef(null);
  const imageRef = useRef(null);
  const heroRef = useRef(null);
  const locationRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(TextPlugin);

    const textElement = textRef.current;

    if (textElement) {
      const spans = textElement.querySelectorAll(".text-line");

      gsap.set(spans, { opacity: 0 });

      gsap.fromTo(
        spans,
        { rotation: 5, y: 100, opacity: 0 },
        {
          rotation: 0,
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: Power3.easeOut,
          delay: 0.5, // Add a delay of 0.5s
        }
      );
    }

    const locationElement = locationRef.current;

    if (locationElement) {
      const locationText = locationElement.textContent;
      const randomizedText = generateRandomizedText(locationText);

      gsap.to(locationElement, {
        innerHTML: randomizedText,
        duration: 0.1,
        onComplete: () => {
          gsap.to(locationElement, {
            innerHTML: locationText,
            duration: 0.5,
            ease: "power3.out",
          });
        },
        delay: 0.1, // Add a delay of 0.5s
      });
    }

    const handleScroll = () => {
      const imageElement = imageRef.current;
      const heroElement = heroRef.current;

      if (!imageElement || !heroElement) return;

      const { top } = imageElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (top <= windowHeight) {
        const parallaxValue = -scrollPosition * 0.2;
        gsap.to(imageElement, { y: parallaxValue, ease: "power2.out" });
        // gsap.to(heroElement, { y: parallaxValue * 2, ease: "power2.out" });
      } else {
        gsap.to(imageElement, { y: 0 });
        // gsap.to(heroElement, { y: 0 });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const generateRandomizedText = (text) => {
    let randomizedText = "";
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (char === " ") {
        randomizedText += " ";
      } else {
        randomizedText += getRandomChar();
      }
    }
    return randomizedText;
  };

  const getRandomChar = () => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
  };

  return (
    <div className="hero" ref={heroRef}>
      <div className="hero__location" ref={locationRef}>
        San Diego, CA
      </div>
      <div className="hero__content grid">
        <div className="hero__title">
          <h1 ref={textRef}>
            <span className="text-mask"><div className="text-line">Product</div></span>
            <span className="text-mask"><div className="text-line">Designer</div></span>
            <span className="text-mask"><div className="text-line">& UX</div></span>
            <span className="text-mask"><div className="text-line">Innovator</div></span>
          </h1>
        </div>
        <div className="hero__intro">
          <p>
            With a focus on user-centered design, I craft immersive digital experiences that blend creativity, storytelling, and meticulous design. My goal is to deliver products that captivate users, drive meaningful engagement, and solve complex business challenges, creating lasting impact and reshaping the possibilities of digital product experiences.
          </p>
        </div>
      </div>
      <div className="hero__svg--transition">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1917.19 456.47">
          <g id="Layer_2_copy_11" data-name="Layer 2 copy 11">
            <polygon points="1460.72 273.89 1460.72 273.88 1460.72 182.59 1552.01 182.59 1552.01 273.88 1643.31 273.88 1643.31 365.18 1734.6 365.18 1734.6 273.88 1825.9 273.88 1825.9 182.59 1734.6 182.59 1734.6 273.88 1643.31 273.88 1643.31 182.59 1643.31 91.29 1734.6 91.29 1825.9 91.29 1917.19 91.29 1917.19 182.59 1917.19 273.88 1917.19 365.18 1917.19 456.47 1825.9 456.47 1734.6 456.47 1643.31 456.47 1552.01 456.47 1460.72 456.47 1369.42 456.47 1278.13 456.47 1186.83 456.47 1186.83 365.18 1278.13 365.18 1278.13 270.213 1369.42 270.213 1369.42 365.18 1460.72 365.18 1460.72 273.89" />
            <polygon points="1369.42 273.88 1369.42 273.89 1278.13 273.89 1278.13 273.88 1186.83 273.88 1186.83 182.59 1278.13 182.59 1369.42 182.59 1369.42 273.88" />
            <rect x="1552.01" width="91.3" height="91.29" />
            <rect x="1369.42" y="91.29" width="91.3" height="91.3" />
            <polygon points="1186.83 273.88 1186.83 365.18 1095.54 365.18 1095.54 456.47 1004.25 456.47 912.95 456.47 821.66 456.47 730.36 456.47 639.07 456.47 547.77 456.47 456.48 456.47 365.18 456.47 365.18 365.18 456.48 365.18 456.48 273.88 547.77 273.88 547.77 365.18 639.07 365.18 639.07 273.88 547.77 273.88 547.77 182.59 639.07 182.59 639.07 91.29 730.36 91.29 730.36 182.59 821.66 182.59 821.66 273.88 730.36 273.88 730.36 365.18 821.66 365.18 821.66 273.88 912.95 273.88 912.95 365.18 1004.25 365.18 1004.25 273.88 912.95 273.88 912.95 182.59 1004.25 182.59 1095.54 182.59 1095.54 273.88 1186.83 273.88" />
            <rect x="1095.54" y="91.29" width="91.29" height="91.3" />
            <rect x="912.95" width="91.3" height="91.29" />
            <rect x="821.66" y="91.29" width="91.29" height="91.3" />
            <rect x="547.77" width="91.3" height="91.29" />
            <polygon points="365.18 273.88 365.18 365.18 273.89 365.18 273.89 456.47 182.59 456.47 91.3 456.47 0 456.47 0 365.18 0 273.88 91.3 273.88 91.3 365.18 182.59 365.18 182.59 273.88 91.3 273.88 91.3 182.59 182.59 182.59 182.59 91.29 273.89 91.29 273.89 182.59 273.89 273.88 365.18 273.88" />
            <rect y="91.29" width="91.3" height="91.3" />
          </g>
          <g id="Layer_16" data-name="Layer 16" opacity="0.85">
            <rect x="547.77" y="273.88" width="91.3" height="91.3" />
            <polygon points="1460.72 273.88 1460.72 273.89 1369.42 273.89 1369.42 273.88 1369.42 182.59 1460.72 182.59 1460.72 273.88" />
            <rect x="91.3" y="273.88" width="91.29" height="91.3" />
            <rect x="821.66" y="182.59" width="91.29" height="91.29" />
            <rect x="1734.6" y="182.59" width="91.3" height="91.29" />
            <rect x="1643.31" y="273.88" width="91.29" height="91.3" />
            <rect x="1278.13" width="91.29" height="91.29" />
            <rect x="365.18" y="91.29" width="91.3" height="91.3" />
          </g>
        </svg>
      </div>
      <div className="hero__image">
        <img ref={imageRef} src={sanDiegoImage} alt="San Diego" />
      </div>
    </div>
  );
}

export default HomeHero;
